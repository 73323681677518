import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "/waarom-fitmark",
    name: "waaromFitmark",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/_WhyFitmark.vue"),
    meta: { title: "Fitmark: Bootcamp & Personal Training - Fitmark" },
  },
  {
    path: "/personal-training",
    name: "personalTraining",
    component: () => import("../views/_PersonalTraining.vue"),
    meta: { title: "Personal training - Fitmark" },
  },
  // {
  //   path: "/bootcamp-training",
  //   name: "bootcampTraining",
  //   component: () => import("../views/_BootcampTraining.vue"),
  //   meta: { title: "Bootcamp training - Fitmark" },
  // },
  {
    path: "/sportmassage",
    name: "sportmassage",
    component: () => import("../views/_SportMassage.vue"),
    meta: { title: "Sportmassage - Fitmark" },
  },
  // {
  //   path: 'surfles',
  //   name: 'surfles',
  //   component: () => import('../views/_Surfles.vue')
  // }
  {
    path: "/over-mark",
    name: "overMark",
    component: () => import("../views/_OverMark.vue"),
    meta: { title: "Over Mark - Fitmark" },
  },
  {
    path: "/over-trainers",
    name: "overTrainers",
    component: () => import("../views/_OverTrainers.vue"),
    meta: { title: "Over Trainers - Fitmark" },
  },
  {
    path: "/corona-proof",
    name: "coronaProof",
    component: () => import("../views/_CoronaProof.vue"),
    meta: { title: "Covid - Fitmark" },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/_Contact.vue"),
    meta: { title: "Contact - Fitmark" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
