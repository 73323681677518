<template>
  <div>
    <sprite />
    <navbar />
    <router-view @set-scroll="setBodyScroll" />
    <!-- <modal-promo v-if="modalOpen" @close="closeModal" /> -->
    <modal-we-are-hiring v-if="modalOpen" @close="closeModal" />
  </div>
</template>

<script>
import ModalWeAreHiring from "./components/ModalWeAreHiring.vue";
// import ModalPromo from "./components/ModalPromo.vue";
import Sprite from "./components/Sprite.vue";
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    ModalWeAreHiring,
    // ModalPromo,
    Sprite,
    Navbar,
  },
  data: function () {
    return {
      modalOpen: undefined,
    };
  },
  created() {
    this.modalOpen = !this.weAreHiringModalSeen;
  },
  methods: {
    closeModal() {
      this.modalOpen = false;
      localStorage.setItem("weAreHiringModalSeen", "true");
    },
    setBodyScroll(modalOpen) {
      if (modalOpen) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
  },
  computed: {
    bodyClass() {
      return { "modal-open": this.modalOpen };
    },
    weAreHiringModalSeen() {
      return localStorage.getItem("weAreHiringModalSeen") === "true";
    },
  },
  watch: {
    // newValue, oldValue
    modalOpen(newValue) {
      this.setBodyScroll(newValue);
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Fitmark";
      },
    },
  },
};
</script>

<style>
body {
  background: #fafafa url("assets/images/korrel-500.jpg");
  background-size: 250px;
}
</style>
