<template>
  <div class="navbar">
    <div class="navigation">
      <input
        class="navigation__trigger md:hidden"
        type="checkbox"
        id="toggleNav"
        ref="toggleNav"
      />
      <header class="navigation__header">
        <label class="navigation__hamburger" for="toggleNav">
          <svg
            class="h-6 w-6 md:hidden navigation__hamburger-vector"
            viewBox="0 0 24 24"
          >
            <path
              v-if="isOpen"
              fill-rule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
            />
            <path
              v-if="!isOpen"
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          </svg>
        </label>

        <router-link class="navigation__logo" to="/">
          <LogoType class="navigation__logo-vector" />
        </router-link>
      </header>

      <nav class="navigation__links">
        <ul class="navigation__list">
          <li class="navigation__list-item">
            <router-link to="/" @click.native="closeOverlayOnMobile">
              Waarom Fitmark?
            </router-link>
          </li>
          <li class="navigation__list-item md:ml-4 lg:ml-8">
            <!-- <router-link class="hidden md:block" to="/diensten">Diensten</router-link> -->
            <a class="hidden md:block">Diensten</a>
            <ul class="navigation__sub-list">
              <li class="navigation__sub-list-item">
                <router-link
                  to="/personal-training"
                  @click.native="closeOverlayOnMobile"
                >
                  Personal Training
                </router-link>
              </li>
              <li class="navigation__sub-list-item">
                <router-link
                  to="/sportmassage"
                  @click.native="closeOverlayOnMobile"
                >
                  Sportmassage
                </router-link>
              </li>
            </ul>
          </li>
          <li class="navigation__list-item md:ml-4 lg:ml-8">
            <router-link
              to="/over-trainers"
              @click.native="closeOverlayOnMobile"
              >Over trainers</router-link
            >
          </li>
          <li class="navigation__list-item md:ml-4 lg:ml-8">
            <router-link to="/corona-proof" @click.native="closeOverlayOnMobile"
              >Covid</router-link
            >
          </li>
          <li class="navigation__list-item md:ml-4 lg:ml-8">
            <router-link to="/contact" @click.native="closeOverlayOnMobile"
              >Contact</router-link
            >
          </li>
        </ul>
      </nav>
    </div>

    <!-- <button class="navbar__cta  cta">Ik doe mee</button> -->
  </div>
</template>

<script>
import LogoType from "./LogoType";

export default {
  name: "Header",
  components: {
    LogoType,
  },
  data: function () {
    return {
      isOpen: false,
    };
  },
  methods: {
    closeOverlayOnMobile() {
      if (!window.matchMedia("(min-width: 768px)").matches) {
        this.$refs.toggleNav.click();
      }
    },
  },
};
</script>
