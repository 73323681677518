<template>
  <clipPath
    id="swirl"
    clipPathUnits="objectBoundingBox"
    :transform="scale"
    :viewBox="`0 0 ${viewBox[0]} ${viewBox[1]}`"
  >
    <path d="M771.3,103.8c-15.6,0-25.1-17.6-38.1-44.4C719.8,31.6,704.7,0,671.4,0S623,31.5,609.6,59.4c-12.9,26.9-22.5,44.4-38.1,44.4s-25.1-17.6-38.1-44.4C520,31.6,504.8.1,471.6.1s-48.4,31.6-61.8,59.4c-12.9,26.9-22.5,44.4-38.1,44.4s-25.3-17.5-38.2-44.4C320.1,31.7,304.9.1,271.7.1c-.6,0-1.2.1-1.7.1-32.1,1-47,31.9-60.2,59.4C196.9,86.5,187.3,104,171.7,104s-25.1-17.6-38.1-44.4C120.2,31.8,105,.3,71.8.3S23.4,31.9,10,59.7C6.4,66.9,3.2,73.5,0,79.3V121c15.2-11.4,24.9-31.5,33.8-50,13-26.9,22.5-44.4,38.1-44.4S97,44.2,110,71c13.4,27.8,28.5,59.4,61.8,59.4S220.2,98.9,233.6,71c12.9-26.8,22.5-44.4,38.1-44.4s25.1,17.6,38.1,44.5c13.4,27.7,28.5,59.3,61.8,59.3a19.42,19.42,0,0,0,2.4-.2c31.7-1.4,46.5-32.1,59.6-59.3,13-26.9,22.5-44.4,38.1-44.4s25.1,17.6,38.1,44.4c13.4,27.8,28.5,59.4,61.8,59.4S620,98.8,633.4,70.9c12.9-26.8,22.5-44.4,38.1-44.4S696.6,44.1,709.6,71c13.4,27.7,28.5,59.3,61.8,59.3a13.25,13.25,0,0,0-.1-26.5Z" />
  </clipPath>
</template>

<script>
export default {
  name: 'Swirl',
  data () {
    return {
      viewBox: [784.5, 130.4]
    }
  },
  computed: {
    scale () {
      return `scale(${1 / this.viewBox[0]} ${1 / this.viewBox[1]})`
    }
  }
}
</script>
