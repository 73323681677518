<template>
  <clipPath
    id="arrow"
    clipPathUnits="objectBoundingBox"
    :transform="scale"
    :viewBox="`0 0 ${viewBox[0]} ${viewBox[1]}`"
  >
    <!-- class="cls-1" -->
    <path d="M562.84,44.8c-.4,2-.6,4.1-1.1,6.1-2.2,9.9-7.9,17.5-16.1,23.3-7.4,5.2-15.7,8.3-24.4,10.4a107.19,107.19,0,0,1-21.1,2.9,3.7,3.7,0,0,0-1.9.7,65.75,65.75,0,0,0-12.7,9.5c-10.8,9.9-19.9,21.3-28.2,33.3-14,20.2-25.5,41.7-35.8,64-6.6,14.3-12.8,28.7-19.5,42.9a577.54,577.54,0,0,1-51.6,88.7c-11.1,15.5-22.9,30.4-36.6,43.7-7.1,6.9-14.5,13.4-23.1,18.3-2.4,1.3-5,2.3-7.5,3.4-.6.3-1.2.9-2,.6-.5-.2-.9-.3-1.4-.5-6.9-2.3-12.7-6.3-18.3-10.7-10.3-8.1-19.2-17.4-27.7-27.3-20.8-24.2-38-50.8-53.6-78.5a744.76,744.76,0,0,1-33.1-67.8c-10.5-24-22.3-47.3-36.7-69.4-8.7-13.4-18.2-26.1-29.6-37.3a114.48,114.48,0,0,0-13-11,16.64,16.64,0,0,0-9.1-2.9C45,86.2,31.94,83.2,20,76c-8-4.8-14.3-11.3-17.4-20.3-4-11.6-3.8-22.9,3-33.6,3.9-6.2,9.5-10.6,15.8-14A64.27,64.27,0,0,1,47.84.2a3,3,0,0,0,1-.2h8.6c2.1.3,4.3.5,6.4.8a90.47,90.47,0,0,1,28.9,10.1c13.7,7.3,25.7,16.9,36.9,27.5a327.54,327.54,0,0,1,38.2,44.1c4.2,5.7,8.6,11.3,12,17.4,12.3,22,24.3,44.3,35.3,67,5.5,11.3,10.4,22.7,15.8,34.1a464.43,464.43,0,0,0,25.8,48.2c4.4,7.1,9.1,14,15,20a41.77,41.77,0,0,0,6.5,5.3,5.4,5.4,0,0,0,6.5.1,38.64,38.64,0,0,0,5-3.8c5-4.7,9-10.2,12.9-15.9a345.1,345.1,0,0,0,23.2-41c7.2-14.8,13.8-29.9,21.1-44.7,8.4-17,17.3-33.8,26.2-50.6,5.7-10.9,11.6-21.7,18.9-31.6,14.1-19.2,29.2-37.6,47-53.5a157,157,0,0,1,32.8-23.2,90.13,90.13,0,0,1,27-9.3c2.2-.4,4.5-.6,6.7-.9h8.6c1.1.2,2.3.3,3.4.5a64,64,0,0,1,28.9,10.8c7.5,5.2,12.9,11.9,15.2,20.9.5,1.9.7,3.8,1.1,5.7C562.84,40.2,562.84,42.5,562.84,44.8Z"/>
  </clipPath>
</template>

<script>
export default {
  name: 'Arrow',
  data () {
    return {
      viewBox: [562.84, 392.68]
    }
  },
  computed: {
    scale () {
      return `scale(${1 / this.viewBox[0]} ${1 / this.viewBox[1]})`
    }
  }
}
</script>
