<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="0"
    height="0"
  >
    <defs>
      <swirl />
      <arrow />
    </defs>
  </svg>
</template>

<script>
import Swirl from './Icons/Swirl'
import Arrow from './Icons/Untitled-2-copy.vue'

export default {
  name: 'Sprite',
  components: {
    Swirl,
    Arrow
  }
}
</script>
