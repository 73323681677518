<template>
  <modal @close="$emit('close')">
    <template v-slot:modal__body>
      <div class="modal-body grid px-5 pb-5">
        <img
          class="rounded"
          width="760"
          alt="Bootcamp trainer gezocht"
          src="../assets/images/modal_trainer_gezocht.png"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "./Modal.vue";

export default {
  name: "ModalWeAreHiring",
  components: {
    Modal,
  },
};
</script>

<style lang="scss"></style>
