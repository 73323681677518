<template>
  <div
    class="modal block py-5 bg-gray-900 bg-opacity-75"
    tabindex="-1"
    role="dialog"
    @click="$emit('close')"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content shadow-lg">
        <div class="modal-header p-5 pb-3">
          <slot name="modal__heading"></slot>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="$emit('close')"
          ></button>
        </div>
        <slot name="modal__body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  emits: ["modal:close"],
};
</script>

<style lang="scss">
.modal-open {
  overflow: hidden;
}

.btn-close {
  pointer-events: auto;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  -webkit-appearance: button;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
</style>
